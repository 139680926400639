<template>
  <div
    id="tooltip"
    class="tooltip-container d-flex flex-column p-2"
    :style="{ left: build.left + 'px', top: build.top + 'px' }"
  >
    <h5>{{ build.question }}</h5>
  </div>
</template>

<script>
export default {
  name: 'icon-tooltip',
  props: {
    buildData: Object
  },
  data() {
    return {
      build: this.buildData
    }
  }
}
</script>

<style lang="scss">
.tooltip-container {
  position: absolute;
  visibility: hidden;
  background: $black;
  padding: 1rem;

  &.active {
    pointer-events: all;
    z-index: 5;
    visibility: visible;
  }

  .tooltip-button {
    cursor: pointer;
    font-size: $font-md;

    &:hover {
      font-weight: 900;
      color: $highlight-color;
    }
  }
}
</style>
