<template>
  <svg ref="parallelSvg" id="parallelSvg" :width="width" :height="height">
    <g :style="{ transform: 'translate(' + 100 + ',' + 30 + ')' }">
      <g class="foreground">
        <path
          :class="{
            active: path.active === true,
            'decrease-width': decreaseWidth === true,
            'highlight-cord': path.highlightCord === true
          }"
          v-for="(path, index) in pathData"
          :key="'foreground' + index"
          :d="path.d"
          :stroke-opacity="path.strokeOpacity"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
import { scalePoint } from 'd3-scale'
import { line } from 'd3-shape'
import { axisLeft } from 'd3-axis'
import { brushY, brushSelection } from 'd3-brush'
import { select, selectAll } from 'd3-selection'
import { mapGetters } from 'vuex'
// GLOBALS
let clearBrushFlag = false
const modelsWithinExtent = []
const activeDimensions = []
let xScale = scalePoint()
let yScale = {}
let dragging = {}
let linePath = line()
let axis = axisLeft()
export default {
  name: 'parallelCoordChart',
  data() {
    return {
      margin: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      svg: null,
      pathData: [],
      decreaseWidth: false,
      width: window.width
    }
  },
  props: {
    modelData: Array
  },
  mounted() {
    this.init()
    // calls the top 10 data results from the entire dataset equally weighted
    this.callDefaultModels()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    height() {
      return 300 - this.margin.top - this.margin.bottom
    },
    ...mapGetters({
      metricObject: 'getMetricObject',
      inputObject: 'getInputObject',
      inputHeaders: 'getInputHeaders',
      metricHeaders: 'getMetricHeaders'
    }),
    dimensions() {
      return this.inputHeaders.concat(this.metricHeaders)
    }
  },
  methods: {
    init() {
      this.width =
        document.getElementById('parallelContainer').getBoundingClientRect()
          .width - 100
      this.svg = select('#parallelSvg').append('g')
      this.createColumns()
      window.addEventListener('resize', this.onResize)
    },
    /**
     * Calls default models top 8 results
     */
    callDefaultModels() {
      this.updateSelectedModels(this.modelData, this.dimensions)
    },
    onResize() {
      const { height, checkAxisObject } = this
      this.width =
        document.getElementById('parallelContainer').getBoundingClientRect()
          .width - 100
      xScale.domain(this.dimensions).range([0, this.width])
      Object.values(yScale).forEach(scale => scale.range([height, 0]))
      this.pathData = this.getPathData()
      const g = this.svg
        .selectAll('.dimension')
        .attr('transform', d => 'translate(' + xScale(d) + ')')
      g.selectAll('.axis').each(function (d) {
        checkAxisObject(this, d)
      })
    },
    updateSelectedModels(selectedModels, dimensions) {
      this.$emit('updateSelectedModels', selectedModels, dimensions)
    },
    getPathData() {
      /**
       * returns the point located on the axis
       */
      const position = d => {
        const v = dragging[d]
        return v == null ? xScale(d) : v
      }
      /**
       * returns SVG path per dimension of axis
       */
      const path = d => {
        return linePath(
          this.dimensions.map(p => {
            return [position(p), yScale[p](d[p])]
          })
        )
      }
      return this.modelData.map(obj => {
        obj.active = true
        obj.strokeOpacity = 0.3
        obj.d = path(obj)
        return obj
      })
    },
    checkAxisObject(element, d) {
      // check to see if custom filter mode ticks are in settings object
      // default is []
      // custom ['x' , 'y'] array of length 2
      const checkFilterModeticks = (filterModeTicks, i, ticks) => {
        if (filterModeTicks.length === 2) {
          let [filterLowTick, filterHighTick] = filterModeTicks
          return i === 0 ? filterLowTick : filterHighTick
        } else {
          return i === 0 ? ticks[0] : ticks[ticks.length - 1]
        }
      }
      // check if metric object
      if (this.metricObject[d]) {
        const ticks = this.metricObject[d].ticks
        const filterModeTicks = this.metricObject[d].filterModeTicks
        const min = yScale[d].invert(this.height)
        const max = yScale[d].invert(0)
        select(element).call(
          axis
            .scale(yScale[d])
            .tickValues([min, max])
            .tickFormat((_, i) =>
              checkFilterModeticks(filterModeTicks, i, ticks)
            )
        )
        // check if input object
      } else if (this.inputObject[d]) {
        select(element).call(
          axis
            .scale(yScale[d])
            .tickValues(Object.keys(this.inputObject[d].marks))
            .tickFormat(n =>
              this.inputObject[d].marks[n] !== undefined
                ? this.inputObject[d].marks[n].label
                : ' '
            )
        )
      }
    },
    createColumns() {
      const labels = {}
      // assign vue methods to the local closure
      const {
        metricObject,
        inputObject,
        height,
        width,
        updateSelectedModels,
        checkAxisObject,
        brushMove
      } = this // vue instance
      // creates two arrays that track which dimensions are active
      // and which extents are within the bounds of the active brushes
      const brushEnd = () => {
        return { models: modelsWithinExtent, dimensions: activeDimensions }
      }
      xScale.domain(this.dimensions).range([0, width])
      this.inputHeaders.forEach(header => {
        labels[header] = inputObject[header].label
        yScale[header] = inputObject[header].scaleLinear
        yScale[header].range([height, 0])
      })
      this.metricHeaders.forEach(header => {
        labels[header] = metricObject[header].label
        yScale[header] = metricObject[header].scaleLinear
        yScale[header].range([height, 0])
      })
      this.pathData = this.getPathData()
      // this.pathData = this.modelData.map((obj) => {
      //   // passes data object through
      //   obj.active = true
      //   obj.strokeOpacity = 0.3
      //   // should return path for svg
      //   obj.d = getPath(obj)
      //   return obj
      // })
      let g = this.svg
        .selectAll('.dimension')
        .data(this.dimensions)
        .enter()
        .append('g')
        .attr('class', 'dimension')
        .attr('transform', d => 'translate(' + xScale(d) + ')')
      // Add an axis and title.
      g.append('g')
        .attr('class', 'axis')
        .each(function (d) {
          checkAxisObject(this, d)
        })
        .append('text')
        .attr('class', 'dimension-label sublabel')
        .style('text-anchor', 'middle')
        .attr('y', -15)
        .text(d => labels[d])
      // Add and store a brush for each axis in the y object
      g.append('g')
        .attr('class', 'brush')
        .each(function (d) {
          select(this).call(
            (yScale[d].brush = brushY()
              .extent([
                [-10, 0],
                [10, height]
              ])
              // .on("start", brushstart)
              .on('brush', brushMove)
              .on('end', function () {
                // checks to see if brushes are being cleared
                // only call when clearBrush is not running
                if (!clearBrushFlag) {
                  const b = brushEnd()
                  // does the brush have data
                  if (b['models'].length > 0) {
                    updateSelectedModels(b['models'], b['dimensions'])
                  }
                }
              }))
          )
        })
        .selectAll('rect')
        .attr('x', -8)
        .attr('width', 16)
    },
    /**
     * @default {void}
     *  calls brush.move
     *  clears brush extents and resets the chart
     *  calls default models
     */
    clearBrushes() {
      clearBrushFlag = true
      this.dimensions.forEach(d =>
        selectAll('.brush').call(yScale[d].brush.move, null)
      )
      clearBrushFlag = false
      this.callDefaultModels()
    },
    toggleWidth(flag) {
      this.decreaseWidth = flag
    },
    highlightLineByID(ID, toggle) {
      if (toggle) {
        this.pathData = this.pathData.map(d => {
          let matchID = d['iteration']
          d['highlightCord'] = matchID === ID
          d['strokeOpacity'] = matchID === ID ? 1 : 0
          return d
        })
      } else {
        this.pathData = this.pathData.map(d => {
          d['highlightCord'] = false
          d['strokeOpacity'] = 0.3
          return d
        })
      }
    },
    brushMove() {
      // tracts brush extents
      let extents = []
      // tracks active dimensions
      activeDimensions.length = 0
      // var yScale = this.yScale
      var decreaseWidth = this.toggleWidth
      var increaseWidthValue = 25
      // tracks models within extent for API call
      // modelsWithinExtent = []
      modelsWithinExtent.length = 0
      // select all brushes and loop through for current extents
      this.svg
        .selectAll('.brush')
        .filter(function (d) {
          return brushSelection(this)
        })
        .each(function (d) {
          activeDimensions.push(d)
          extents.push([
            yScale[d].invert(brushSelection(this)[0]),
            yScale[d].invert(brushSelection(this)[1])
          ])
        })
      // set active dimensions to instance
      // activeDimensions = ad
      /**
       * Loops through the active brushes and sees which
       * paths are within the given bounds of each brush
       *
       * inner loop check returns True/False
       * outer loop returns either inline or non display style
       */
      this.pathData = this.pathData.map(d => {
        if (activeDimensions.length <= 0) {
          d.active = true
        } else {
          activeDimensions.every((p, i) => {
            var value = d[p]
            if (
              extents[i][1] <= parseFloat(value) &&
              parseFloat(value) <= extents[i][0]
            ) {
              d.active = true
              return true
            } else {
              d.active = false
              return false
            }
          })
          if (d.active === true) {
            // this array contains current models iteration numbers within brush extent
            if (modelsWithinExtent.length <= increaseWidthValue) {
              decreaseWidth(true)
            } else {
              decreaseWidth(false)
            }
            modelsWithinExtent.push(d)
          }
        }
        return d
      })
    }
  }
}
</script>

<style lang="scss">
$text-shadow-color: black;
#parallelSvg {
  overflow: visible;
}
#parallelContainer {
  z-index: 2;
}
.foreground path {
  fill: none;
  stroke: rgba(250, 250, 250, 0.01);
  stroke-width: 1px;
  &.active {
    stroke: $highlight-color !important;
  }
  &.active.decrease-width {
    stroke-width: 4px !important;
  }
  &.highlight-cord {
    stroke: $highlight-color !important;
    stroke-width: 5px;
    stroke-linecap: round;
  }
}
.brush .extent {
  fill-opacity: 0.3;
  stroke: $white;
  shape-rendering: crispEdges;
}
.axis line,
.axis path {
  fill: none;
  stroke: $border-color;
}
.axis text {
  fill: $white;
  user-select: none;
  pointer-events: none;
  text-shadow: 0 1px 0 $text-shadow-color, 1px 0 0 $text-shadow-color,
    0 -1px 0 $text-shadow-color, -1px 0 0 $text-shadow-color;
}
.axis .tick {
  user-select: none;
  pointer-events: none;
  font-family: 'Roboto Mono', monospace;
  font-size: 0.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1px;
}
</style>
