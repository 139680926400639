<template>
  <div
    class="telescope-icon-svg"
    title="open in explore?"
    @click.stop="routeToExplore"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 25 25"
      style="enable-background: new 0 0 25 25"
      xml:space="preserve"
    >
      <g id="telescope">
        <path
          id="Shape"
          class="st0 highlight-fill"
          d="M24.9,9.9L22,2.2c-0.1-0.3-0.4-0.6-0.7-0.7c-0.3-0.1-0.7-0.1-1,0l-4.2,1.9
          c-0.6,0.3-0.9,1-0.7,1.7L2.9,11.8c-0.3,0.2-0.5,0.6-0.4,0.9l0.3,0.9l-2.5,1.1c-0.3,0.1-0.5,0.5-0.3,0.9l0.8,2.1
          C0.9,17.9,1,18,1.2,18.1s0.3,0.1,0.5,0L4.2,17l0.3,0.9c0.2,0.4,0.6,0.5,0.9,0.4l5.2-2c0.1,0.1,0.1,0.1,0.2,0.2l-2,6.6
          c0,0.1,0,0.2,0,0.3s0.2,0.1,0.3,0.1h0.7c0.1,0,0.3-0.1,0.3-0.2l1.8-6.1c0.5,0.1,0.8,0.1,1.3,0l1.8,6.1c0,0.1,0.2,0.2,0.3,0.2h0.7
          c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0-0.3l-2-6.6c0.5-0.5,0.7-1.2,0.7-1.9l3.5-1.4c0.3,0.4,0.8,0.7,1.5,0.4l4.2-1.9
          C24.9,11.4,25.2,10.6,24.9,9.9z M1.7,16.6l-0.3-0.8l1.9-0.9l0.3,0.8L1.7,16.6z M12.5,15.9c-0.7,0-1.2-0.6-1.2-1.4
          c0-0.8,0.6-1.4,1.2-1.4c0.7,0,1.2,0.6,1.2,1.4C13.7,15.2,13.2,15.9,12.5,15.9L12.5,15.9z M14.7,13.2c-0.6-1.2-1.9-1.8-3-1.3
          S9.8,13.6,10,15l-4.6,1.8l-1.5-4l12-6.4l2.1,5.6L14.7,13.2z M19.5,12.3l-2.9-7.6l4.2-1.9l2.9,7.6L19.5,12.3z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'exploreIcon',
  data() {
    return {}
  },
  props: {
    currentModelID: Number
  },
  computed: {},
  methods: {
    routeToExplore() {
      this.$router.push({
        name: 'Explore',
        params: { ID: this.currentModelID, filterUpdate: true }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.st0 {
  fill: #979797;
}
svg {
  overflow: visible;
}
</style>
