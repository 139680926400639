<template>
  <div
    class="icon-svg heart-icon-svg"
    title="add to collection"
    :class="{ active: heartActive === true }"
    @click.stop="addToCollection"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 25 25"
      style="enable-background: new 0 0 25 25"
      xml:space="preserve"
    >
      <g id="Symbols">
        <g id="heart_inactive">
          <path
            id="Shape"
            class="st0 highlight-stroke"
            d="M3,3.3c1.3-1.1,3-1.6,4.6-1.5c1.7,0.1,3.3,0.9,4.6,2.2c1.9-1.3,3.5-2.1,5.2-2.2
          c1.7-0.1,3.3,0.4,4.6,1.5c1.6,1.4,2.3,3.2,2.4,4.9c0.1,2-0.7,4-1.9,5.3l0,0L14,22.5c-0.4,0.4-0.9,0.7-1.5,0.7
          c-0.6,0-1.1-0.2-1.5-0.7l0,0l-8.5-8.9c-1.3-1.3-2-3.3-1.9-5.3C0.6,6.5,1.4,4.7,3,3.3z M17.5,3.1c-1.3,0.1-2.6,0.7-3.6,1.7
          l-1.4,1.5l-1.4-1.5c-1-1-2.3-1.6-3.6-1.7C6.2,3,4.9,3.4,3.9,4.3C2.6,5.4,2,6.9,1.9,8.3c-0.1,1.6,0.5,3.3,1.6,4.4l9.2,9.3l8.8-9.3
          c1-1.1,1.6-2.7,1.6-4.4c-0.1-1.5-0.7-2.9-2-4.1C20.1,3.4,18.8,3,17.5,3.1z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
/**
 * @vue-import store mapping store to view
 */
import { mapGetters } from 'vuex'

export default {
  name: 'heartIcon',
  props: {
    currentModelID: Number
  },
  computed: {
    ...mapGetters({
      collectionIDs: 'getCollectionIDs'
    }),
    heartActive() {
      return this.collectionIDs.includes(this.currentModelID) === true
    }
  },
  methods: {
    addToCollection() {
      // this.$store.commit('setCollectionIDs', this.currentModelID)
      this.$store.dispatch('updateCollectionIDs', this.currentModelID)
    }
  }
}
</script>

<style lang="scss" scoped>
.heart-icon-svg {
  &:hover,
  &.active {
    cursor: pointer;

    path {
      stroke: $highlight-color;
    }
  }
}
.st0 {
  fill: none;
  stroke: #979797;
}
svg {
  overflow: visible;
}
</style>
